import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ListGroup from 'react-bootstrap/ListGroup';
import { ChevronRight } from 'react-bootstrap-icons';
import contexts from '../../context/contexts';
import './Sets.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.sets;

const Sets = () => {
	const dispatch = makeDispatcher(useDispatch());
	const { sets } = useStore();

	useEffect(() => {
		if (!sets.data.length) {
			dispatch({ type: 'FETCH_SETS' });
		}
	}, []); // the [] is for when mounting only

	if (!sets.finishedLoading || !sets.data.length) {
		return null;
	}

	const items = sets.data.map(item =>
		<ListGroup.Item className="d-flex justify-content-between" action href={`/set-dances/${item.id}`} key={item.id}>
			{item.title}
		  	<div>
				<span className="figures">
					{Object.keys(item).filter(a => a.startsWith('figure-')).length} figures
				</span>
				<ChevronRight fontSize={24} />
			</div>
	  	</ListGroup.Item>
	);

	return (
		<div className="set-dances">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Irish Set Dances
				</title>
				<meta name="description" content="Learn how to dance Irish traditional Set Dances compiled by Brooks Academy" />
				<meta name="keywords" content="traditional Irish dance, set dancing, Irish music, ceili, steps, sets" />
			</Helmet>
			<h1>Set Dances</h1>
			<p className="box box-content unbordered">
				Below are a number of set dances compiled by <a href="/about">Brooks Academy</a>.
				They were compiled over the years by visiting the places where they are danced
				and learned directly from the locals.

				Our set dance classes are casual, and all levels are welcome, and we are especially
				welcoming to beginners. Set dancing is a great social outlet, as well as exercise
				for the body and the mind.
			</p>
			<ListGroup className="box box-content unbordered">
				{items}
			</ListGroup>
		</div>
	);
};

export default Sets;
