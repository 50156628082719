import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import Badge from 'react-bootstrap/Badge';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ListGroup from 'react-bootstrap/ListGroup';
import { LinkContainer } from 'react-router-bootstrap';
import { Book, FullscreenExit, ListTask, Printer } from 'react-bootstrap-icons';
import contexts from '../../context/contexts';
import './Sets.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.sets;

const Couples = ({ couples, abbreviate = true }) => {
	if (abbreviate) {
		if (couples == 'all') {
			return '(All)';
		} else if (couples == 'tops') {
			return '(Tops)';
		} else if (couples === 'sides') {
			return '(Sides)';
		} else if (couples == 'first-tops') {
			return '(1st Tops)';
		} else if (couples == 'second-tops') {
			return '(2nd Tops)';
		} else if (couples == 'first-sides') {
			return '(1st Sides)';
		} else if (couples == 'second-sides') {
			return '(2nd Sides)';
		}
	} else {
		if (couples == 'all') {
			return 'All four couples';
		} else if (couples == 'tops') {
			return 'Both top couples';
		} else if (couples === 'sides') {
			return 'Both side couples';
		} else if (couples == 'first-tops') {
			return 'First top couple';
		} else if (couples == 'second-tops') {
			return 'Second top couple';
		} else if (couples == 'first-sides') {
			return 'First side couple';
		} else if (couples == 'second-sides') {
			return 'Second side couple';
		}
	}
}

const MusicType = ({ music }) => {
	let name;
	let type;
	if (music === 'hornpipe') {
		type = 'warning';
		name = 'Hornpipes';
	} else if (music === 'jig') {
		type = 'info';
		name = 'Jigs';
	} else if (music === 'march') {
		type = 'light';
		name = 'Marches';
	} else if (music === 'polka') {
		type = 'primary';
		name = 'Polkas';
	} else if (music === 'reel') {
		type = 'danger';
		name = 'Reels';
	} else if (music === 'slide') {
		type = 'dark';
		name = 'Slides';
	}
	type = 'secondary';
	return <Badge bg={type}>{name}</Badge>
}

const Movement = ({ id, main, couples, title, bars }) => {
	return (
		<div className="d-flex">
			<span className="movement-id">
				{id}
			</span>
			<span className={
				cn({ main }, 'movement-title')
			}>
				{title} {main ? "- Figure" : ""} <Couples couples={couples} />
			</span>
			<span className="music-bars">{bars} bars</span>
		</div>
	);
}

const SummaryView = (figure) => {
	const movements = [
		<p key="initial-position">
			{figure.initialPosition.description}
		</p>
	];
	for (const movementKey in figure.movements) {
		const movement = figure.movements[movementKey];
		if (movement.repeatMovement) {
			const repeat = figure.movements[movement.repeatMovement];
			movements.push(
				<Movement
					key={movementKey}
					id={movementKey}
					couples={movement.couples || repeat.couples}
					bars={movement.bars || repeat.bars}
					main={repeat.main}
					title={repeat.title}
				/>
			);

		} else {
			movements.push(
				<Movement
					key={movementKey}
					id={movementKey}
					couples={movement.couples}
					main={movement.main}
					bars={movement.bars}
					title={movement.title}
				/>
			);
		}
	}
	return movements;
};

const FigureView = (figure, {mainOnly = false} = {}) => {
	const movements = [];
	for (const movementId in figure.movements) {
		const movement = figure.movements[movementId];
		if (mainOnly && !movement.main) {
			continue;
		}
		if (movement.description) {
			movements.push(
				<div key={`${movementId}`} className="d-flex">
					<p  className="movement-description">
						{movement.description}
					</p>
					<span className="music-bars">{movement.bars} bars</span>
				</div>
			);
		}
		for (const movementKey in movement.movements) {
			const subMovement = movement.movements[movementKey];
			movements.push(
				<div key={`${movementKey}`}>
					<div className="d-flex submovement">
						<span className="movement-title">
							{subMovement.title} ({movementKey})
						</span>
						<span className="music-bars">{subMovement.bars} bars</span>
					</div>
					<span className="movement-description">
						{subMovement.description}
					</span>
				</div>
			);
		}
		break;
	}

	return (
		<>
			{movements}
		</>
	)
}

const DetailMovementSteps = ({ movement }) => {
	if (!movement.movements) {
		return null;
	}
	const steps = [];
	for (const subMovementId in movement.movements) {
		const subMovement = movement.movements[subMovementId]
		steps.push(
			<li key={subMovementId}>
				{subMovement.description}
			</li>
		);
	}
	return (
		<ol type="a">
			{steps}
		</ol>
	);
};

const DetailFigureTitle = (props) => {
	const {
		figureId,
		music,
		title,
		initialPositionDescription
	} = props;

	return (
		<div key={figureId}>
			<h2>Figure {figureId} - {title}</h2>
			<p>
				<MusicType music={music} />
			</p>
			<p>
				{initialPositionDescription}
			</p>
			<div>
				{props.children}
			</div>
		</div>
	);
}

const DetailMovement = (props) => {
	const {
		movementKey,
		title,
		couples,
		main,
		bars,
		description
	} = props;
	return (
		<div key={movementKey}>
			<h3>{movementKey}. {title}</h3>
			<p>
				<div>{bars} bars</div>
				{main && <div>Main Figure</div>}
				<Couples couples={couples} abbreviate={false} />
			</p>
			<p>
				{description}
			</p>
			{props.children}
		</div>
	);
};

const DetailMovementList = ({ rows: movements }) => {
	return movements.map(movement => (
		<DetailMovement
			key={`movement-${movement.movementKey}`}
			movementKey={movement.movementKey}
			title={movement.title}
			couples={movement.couples}
			main={movement.main}
			bars={movement.bars}
			description={movement.description}
		>
			<DetailMovementSteps movement={movement} />
		</DetailMovement>
	));
}

const DetailFigure = (figureId, figure) => {
	return (
		<DetailFigureTitle
			key={figureId}
			figureId={figureId}
			music={figure.music}
			title={figure.title}
			initialPositionDescription={figure.initialPosition.description}
		>
			<DetailMovementList rows={
				Object.keys(figure.movements).map(movementKey => {
					const movement = figure.movements[movementKey];
					if (movement.repeatMovement) {
						return {
							movementKey,
							couples: movement.couples,
							description: movement.description || `Repeat ${movement.repeatMovement}.`,
						};
					}
					return {
						movementKey,
						...figure.movements[movementKey]
					};
				})
				}
			/>
			<hr key={"banana"} />
		</DetailFigureTitle>
	);
}

const Video = ({ url }) => {
	if (!url) {
		return;
	}
	return (
		<div>
			<iframe
				width="100%"
				height="315"
				src={url}
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				allowFullScreen
			/>
		</div>
	);
};

const CheatSheet = (item) => {
	const cheatSheet = [];
	for (const key in item) {
		if (!key.startsWith("figure-")) {
			continue;
		}
		const figureId = key.split('-')[1];
		const figure = item[key];
		const mainMovement = GetMainMovement(figure);
		cheatSheet.push(
			<div key={key} className="cheat-container">
				<span className="figure-title-container">
					<span className="figure-title">Figure {figureId} - {figure.title || mainMovement?.title}</span>
					<MusicType music={figure.music} />
				</span>
				<Video url={figure.youtube} />
				<div>
					{SummaryView(figure)}
				</div>
			</div>
		);
	}
	return cheatSheet;
}

const CheatSheetFigures = (item) => {
	const cheatSheet = [];
	for (const key in item) {
		if (!key.startsWith("figure-")) {
			continue;
		}
		const figureId = key.split('-')[1];
		const figure = item[key];
		const mainMovement = GetMainMovement(figure);
		cheatSheet.push(
			<div key={key} className="cheat-container">
				<span className="figure-title-container">
					<span className="figure-title main">Figure {figureId} - {figure.title || mainMovement?.title}</span>
					<MusicType music={figure.music} />
				</span>
				<div>
					{FigureView(figure, {mainOnly: true})}
				</div>
			</div>
		);
	}
	return cheatSheet;
}

const GetMainMovement = (figure) => {
	for (const movementId in figure.movements) {
		const movement = figure.movements[movementId];
		if (movement.main) {
			return movement;
		}
	}
	return null;
}

export default function Set () {
	const [viewState, setViewState] = useState('summary');
	const dispatch = makeDispatcher(useDispatch());
	const { sets } = useStore();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (!sets.data.length) {
			dispatch({ type: 'FETCH_SETS' });
		}
	}, []); // the [] is for when mounting only

	if (!sets.finishedLoading || !sets.data.length) {
		return null;
	}

	const item = sets.data.find(a => a.id === params.set);
	if (!item) {
		return navigate('/set-dances');
	}
	const figures = [];
	if (viewState == "details") {
		for (const key in item) {
			if (!key.startsWith("figure-")) {
				continue;
			}
			const figureId = key.split('-')[1];
			const figure = item[key];
			const movements = DetailFigure(figureId, figure);
			figures.push(movements);
		}
	}

	return (
		<div className="set-dances">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{item.title}
				</title>
				<meta name="description" content="TODO" />
				<meta name="keywords" content="traditional Irish dance, set dancing, TODO" />
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/set-dances">
						<Breadcrumb.Item>Set Dances</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{item.title}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>
			<p className="box box-content unbordered main-description">
				{item.description}
			</p>
			<div className="box box-content unbordered">
				<ListGroup horizontal className="view-changer">
					<ListGroup.Item
						active={viewState === "summary"}
						action
						onClick={() => setViewState("summary")}
					>
						<ListTask /> Summary
					</ListGroup.Item>
					<ListGroup.Item
						active={viewState === "figure"}
						action
						onClick={() => setViewState("figure")}
					>
						<FullscreenExit /> Figures only
					</ListGroup.Item>
					<ListGroup.Item
						active={viewState === "details"}
						action
						onClick={() => setViewState("details")}
					>
						<Book /> All details
					</ListGroup.Item>
				</ListGroup>

				{viewState == "summary" && (
					<div className="grid-container view-summary">
						{CheatSheet(item)}
					</div>
				)}
				{viewState == "figure" && (
					<div className="grid-container view-figures">
						{CheatSheetFigures(item)}
					</div>
				)}
				{viewState == "details" && (
					<div className="cheat-container">
						<div className="view-details">
							{figures}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
