import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Event from '../calendar/Event';
import contexts from '../../context/contexts';
import './Home.less';
import gclef from '../../../images/15_henrietta_street.jpg';

const {
	useStore: useEventsStore,
	useDispatch: useEventsDispatch,
	makeDispatcher: makeEventsDispatcher
} = contexts.events;

const {
	useStore: useMessagesStore,
	useDispatch: useMessagesDispatch,
	makeDispatcher: makeMessagesDispatcher
} = contexts.messages;

const ceilis = `
# Céilithe 2024

## North-Side Céilithe

### Balbriggan
#### Gleve North Football Club, Balbriggan
Theresa O'Neill, 086 3718585

**First Wednesday of Month**

8.30 pm - 11.30 pm

* September 4 - Brian Ború
* October 2 - Tara Céilí Band
* November 6 - Templehouse Trio
* December 4 - To be announced

### [Clasaċ](https://clasac.ie/whats-on/clasac-events/)
#### Alphie Byrne Rd., Clontarf
Helen O Sullivan 087 2329160
Mary Whelan 0861937669

**Second Friday of Month**

9.00 p.m. - 12.00 p.m.

* September 13 - Rise the Dust
* October 11 - Brian Ború
* November 8 - Templehouse Trio
* December 13 - Copperplate

### Balgriffin Hall
#### Malahide Rd., D.17
Joe Hughes 0866885374

**Last Saturday of Month**

9.00 p.m. - 12.00 p.m.

* September - Céilí moved to October 5th
* October 5 - Joe Hughes & Pat Walsh
* October 26 - Micheál Sexton
* November 30 - Templehouse Trio
* December 28 - To be Announced

----------

## South-Side Céilithe 2024

### St. Mark's GAA
#### Cookstown Rd., D. 24
Carmel Jameson 0877746713

**Third Saturday of Month**
9.00 p.m. - 12.00 p.m.

* September 21 - Foot Tapper
* October 19 - Glenside
* November 16 - Brian Ború
* December 21 - Legacy
* January 18 - Glenside
* February 15 - Micheál Sexton
* March 15 - Brian Ború
* April 19 - Foot Tapper
* May 17 - Annaly
* June 21 - Legacy
* July 19 - Brian Ború

### Cultúrlann
#### Monkstown
Clare Walsh 0876302758

**Last Friday of Month**
9.00 p.m.  - 12.00 p.m.

* September 27 - Micheál Sexton
* October 25 - Swallows Tail
* November 29 - Annaly
* December 31 - Legacy
`;

export default function Home() {
	const eventsDispatch = makeEventsDispatcher(useEventsDispatch());
	const messagesDispatch = makeMessagesDispatcher(useMessagesDispatch());
	const { events } = useEventsStore();
	const { messages } = useMessagesStore();

	useEffect(() => {
		if (!events.data.length) {
			eventsDispatch({ type: 'FETCH_EVENTS_NEXT' });
		}
		if (!messages.data.length) {
			messagesDispatch({ type: 'FETCH_MESSAGES' });
		}
	}, []); // the [] is for when mounting only

	if (!events.finishedLoading || !messages.finishedLoading) {
		return null;
	}
	const fhf = events.data.find(a => a.title.match(/frank harte festival/i))
	const panels = [];
	const className = classnames('box-content box next-event', { fhf });
	if (events.data.length) {
		const nextEvent = events.data[0];
		panels.push(
			<div key="next-event" className={className}>
				<Event event={nextEvent} more={false} image />
				{nextEvent.location === "pipers-club" &&
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.5138649074356!2d-6.271389883129676!3d53.351957911131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e81d81a3bdd%3A0x5920964dfda5c0e3!2sNa%20P%C3%ADobair%C3%AD%20Uilleann%20(Society%20of%20Irish%20Pipers)!5e0!3m2!1sen!2sie!4v1726398168192!5m2!1sen!2sie"
					width="100%"
					height="450"
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
					style={{borderWidth: 0}}
				>
				</iframe>}
			</div>
		);
	} else {
		// No events, on break
		panels.push(
			<div key="next-event" className={className}>
				<img src={gclef} className="gclef" />
				<Link to="/about">Brooks Academy</Link> is currently on a break and there are no events
				in the <Link to="/calendar">Calendar</Link>. The season runs from September to July,
				so come back soon to see the next season's line up.
			</div>
		);
	}
	if (ceilis && ceilis.length) {
		panels.push(
			<div key="ceilithe" className="box-content box">
				<ReactMarkdown>
					{ceilis}
				</ReactMarkdown>
			</div>
		);
	}

	if (messages.data.length) {
		const message = messages.data[0];
		panels.push(
			<div key="messages" className="message box-content box">
				<Alert variant="light">
					<ReactMarkdown>
						{message.message}
					</ReactMarkdown>
				</Alert>
			</div>
		);
	}

	return (
		<div className="home">
			<div className={classnames('panels', { fhf })}>
				{panels}
			</div>
		</div>
	);
}
